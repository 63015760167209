import React, {Component} from "react"

class MenuView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/menu/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Menukaart</h2>
                <h3>IJssmaken</h3>
                <ul>
                    <li>1 bol: &euro; 2,00</li>
                    <li>2 bollen: &euro; 3,50</li>
                    <li>extra bol: &euro; 1,50</li>
                </ul>
                <ul>
                    <li>Vanille</li>
                    <li>Stracciatella</li>
                    <li>Chocolade</li>
                    <li>Mokka</li>
                    <li>Hazelnoot</li>
                    <li>Pistache</li>
                    <li>Banaan</li>
                    <li>Aardbei</li>
                    <li>Kinder Bueno</li>
                    <li>Oreo</li>
                    <li>Rocher</li>
                    <li>Kokos</li>
                    <li>Cookies</li>
                    <li>Amandel</li>
                    <li>Mango</li>
                </ul>

                <h3>Sorbets</h3>
                <ul>
                    <li>Meloen</li>
                    <li>Citroen</li>
                    <li>Bosvruchten</li>
                    <li>Kiwi</li>
                </ul>

                <h3>IJscoupes</h3>
                <ul>
                    <li>Dame blanche: &euro; 6,00</li>
                    <li>Bresilienne: &euro; 9,00</li>
                </ul>

                <h3>Supplementen</h3>
                <ul>
                    <li>Aardbeiensaus: &euro; 1,00</li>
                    <li>Caramelsaus: &euro; 1,00</li>
                    <li>Chocoladesaus:: &euro; 1,00</li>
                </ul>

                <h3>Dranken</h3>
                <ul>
                    <li>Coca-cola 33cl: &euro; 2,00</li>
                    <li>Coca-cola Zero 33cl: &euro; 2,00</li>
                    <li>Niet bruisend water 33cl: &euro; 2,00</li>
                </ul>
            </div>
        )
    }
}

export default MenuView
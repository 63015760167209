import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import './App.css';
import CallView from "./Components/Call/CallView"
import TrackerView from "./Components/Tracker/TrackerView"
import Header from "./Components/Header"
// import AboutView from "./Components/About/AboutView"
import AboutAppView from "./Components/AboutApp/AboutAppView"
import MenuView from "./Components/Menu/MenuView"
import { createStore, applyMiddleware, compose } from 'redux';
import TrackerReducer from'./Redux/TrackerReducer';
import OverlayReducer from'./Redux/OverlayReducer';
import {Provider} from 'react-redux'
import {combineReducers} from 'redux'
import AddressView from './Components/Address/AddressView';
import AmbiguousAddressView from './Components/Address/AmbiguousAddressView';
import SilentAddressView from './Components/SilentAddress/SilentAddressView';
import AmbiguousSilentAddressView from './Components/SilentAddress/AmbiguousSilentAddressView';
import OverlayHandler from './Components/Overlays/OverlayHandler';
// import ReserverenView from './Components/Reserveren/ReserverenView';
import StatusView from './Components/Status/StatusView';
import NotFound from './Components/Error/NotFound';
// import CadeauView from './Components/Cadeau/CadeauView';
import SetCookieView from './Components/SetCookie/SetCookieView';
import SetTokenView from './Components/SetToken/SetTokenView';
import PrivacyPolicyView from './Components/PrivacyPolicy/PrivacyPolicyView';
import CookiePolicyView from './Components/CookiePolicy/CookiePolicyView';

export let store = createStore(combineReducers({tracker: TrackerReducer, overlay: OverlayReducer}), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

function App() {
  return (
    <Provider store={store}>
    <div>
      <Router>
        <Header></Header> 
        <Switch>
          <Route path="/" exact component={TrackerView} />
          <Route path="/address" exact component={AddressView} />
          <Route path="/address/ambiguous" exact component={AmbiguousAddressView} />
          <Route path="/silentaddress" exact component={SilentAddressView} />
          <Route path="/silentaddress/ambiguous" exact component={AmbiguousSilentAddressView} />
          <Route path="/call" exact component={CallView} />
          {/* <Route path="/about" component={AboutView} /> */}
          <Route path="/aboutapp" component={AboutAppView} />
          <Route path="/menu" component={MenuView} />
          {/* <Route path="/reserveren" component={ReserverenView} /> */}
          <Route path="/status" component={StatusView} />
          {/* <Route path="/cadeau/:barcode?" component={CadeauView} /> */}
          <Route path="/setcookie/:apikey?/:division_id?" component={SetCookieView} />
          <Route path="/settoken" component={SetTokenView} />
          {/* <Route path="/privacypolicy" component={PrivacyPolicyView} /> */}
          {/* <Route path="/cookiepolicy" component={CookiePolicyView} /> */}
          <Route component={NotFound} /> { /* Default route */ }
        </Switch>
        <OverlayHandler></OverlayHandler>
      </Router>
    </div>
    </Provider>
  );
}

export default App;

import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
// import GoogleMapReact from 'google-map-react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import ContainerDimensions from 'react-container-dimensions'
import MapContainer from './MapContainer';
import ErrorImage from '../ErrorImage';
import POIMarker from './POIMarker';
import TruckMarker from './TruckMarker';
import UserMarker from './UserMarker';
import CombinedButton from './CombinedButton';
import UserCenterButton from './UserCenterButton';
import OpenAppButton from './OpenAppButton';
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';
import { setHideHeader, setHideHeaderLinks } from "../../Redux/OverlayActions";
import SilentCallButton from "./SilentCallButton";

const apiIsLoaded = (map, setMap) => {
    setMap(map);
};

class TrackerView extends Component {
    constructor() {
        super();
        this.state = {
            hasInternet: true,
            hasLocationPermission: false,
            deniedLocationPermission: false,
            userLocation: null,
            pois: [],
            karren: [],
            trackid: "firstDrawUser",
            map: null,
            firstDraw: true,
            lastFocusLatitude: 0,
            lastFocusLongitude: 0,

            redirectToAboutApp: false,
        };

        this.setUdid = this.setUdid.bind(this);
        this.checkConsent = this.checkConsent.bind(this);
        // this.checkServerHeartbeat = this.checkServerHeartbeat.bind(this);
        this.getPOIdata = this.getPOIdata.bind(this);
        this.getMapdata = this.getMapdata.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleMapClick = this.handleMapClick.bind(this);
        this.setMap = this.setMap.bind(this);
        this.moveMap = this.moveMap.bind(this);
        this.checkLocationPermission = this.checkLocationPermission.bind(this);
    }
    
    componentDidMount() {
        this.setUdid();
        this.checkConsent();
        // this.checkServerHeartbeat();
        this.checkLocationPermission();
    }

    componentWillUnmount() {
		if (this.getMapdataInterval) clearTimeout(this.getMapdataInterval);
	}

    setUdid() {
        const udid = window.localStorage.getItem('udid') || '';
        if (udid == '') {
            const nav = window.navigator;
            const screen = window.screen;
            let guid = nav.mimeTypes.length;
            guid += nav.userAgent.replace(/\D+/g, '');
            guid += nav.plugins.length;
            guid += screen.height || '';
            guid += screen.width || '';
            guid += screen.pixelDepth || '';

            localStorage.setItem('udid', guid);
        }
    }

    checkConsent() {
        const url = new URL(window.location.href);
        const c = url.searchParams.get("iframe");
        if (c == "no") {
            store.dispatch(setHideHeader(true));
        } else {
            const consent = (window.localStorage.getItem('consent') || false);
            store.dispatch(setHideHeaderLinks(!consent));
            this.setState({
                redirectToAboutApp: !(consent)
            });
        }
    }

    checkLocationPermission() {
        let that = this;
        navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
            if('granted' === PermissionStatus.state) {
                that.setState({
                    hasLocationPermission: true,
                    deniedLocationPermission: false,
                });
            } else if('denied' === PermissionStatus.state) {
                that.setState({
                    hasLocationPermission: false,
                    deniedLocationPermission: true,
                });
            } else {
                that.setState({
                    hasLocationPermission: false,
                    deniedLocationPermission: false,
                });
            }
        });
        if (!navigator.permissions) {
            //This browser does not have the permissions API
            //Fallback to localstorage polyfill
            const geolocationState = localStorage.getItem('geolocation') || '';
            if('granted' === geolocationState) {
                that.setState({
                    hasLocationPermission: true,
                    deniedLocationPermission: false,
                });
            } else if('denied' === geolocationState) {
                that.setState({
                    hasLocationPermission: false,
                    deniedLocationPermission: true,
                });
            } else {
                that.setState({
                    hasLocationPermission: false,
                    deniedLocationPermission: false,
                });
            }
        }
    }

    getPOIdata() {
        const url = process.env.REACT_APP_ICECORP_API_HREF+"v1/pointofinterestmarkerdata?company_id="+process.env.REACT_APP_COMPANY_ID;
        fetch(url)
        .then(response => response.json())
        .then(data => {
            const pois = data.data;

            this.setState({
                pois: pois,
            });
        })
        .catch(() => {
        });
    }

    getMapdata() {
        const url = process.env.REACT_APP_ICECORP_API_HREF+"v1/icecreamvanmarkerdata?company_id="+process.env.REACT_APP_COMPANY_ID+"&has_working_day=1";
        fetch(url)
        .then(response => response.json())
        .then(data => {
            const karren = data.data;

            if (this.state.firstDraw && this.state.map != null) {
                this.state.map.panTo({
                    lat: process.env.REACT_APP_DEFAULT_LAT,
                    lng: process.env.REACT_APP_DEFAULT_LNG,
                });
            }

            karren.forEach(kar => {
                if ("kar"+kar.id == this.state.trackid) {
                    this.moveMap(kar.latitude, kar.longitude);
                }
            });

            let that = this;

            if (this.state.hasLocationPermission) {
                navigator.geolocation.getCurrentPosition(function(geoposition) {
                    const userLocation = {
                        lat: geoposition.coords.latitude,
                        lng: geoposition.coords.longitude
                    }
                    
                    that.setState({
                        userLocation: userLocation,
                    });

                    if (that.state.trackid == "firstDrawUser") {
                        that.moveMap(geoposition.coords.latitude, geoposition.coords.longitude);
                        that.setState({
                            trackid: "user",
                        });
                    }
                });
            }

            this.setState({
                hasInternet: true,
                karren: karren,
                firstDraw: false,
            });

            if (this.getMapdataInterval) clearTimeout(this.getMapdataInterval);
            this.getMapdataInterval = setTimeout(this.getMapdata, 3000);
        })
        .catch(() => {
            if (this.getMapdataInterval) clearTimeout(this.getMapdataInterval);
            this.getMapdataInterval = setTimeout(this.getMapdata, 3000);
            this.setState({
                hasInternet: false,
            });
        });
    }

    static defaultProps = {
        center: {
            lat: process.env.REACT_APP_DEFAULT_LAT,
            lng: process.env.REACT_APP_DEFAULT_LNG,
        },
        zoom: 13
    };

    handleMapClick() {
        this.setState({
            trackid: "",
            lastFocusLatitude: 0,
            lastFocusLongitude: 0,
        });
    }

    handleClick(trackid, lat, long) {
        if (lat != 0 || long != 0) {
            this.moveMap(lat, long);
        }

        this.setState({
            trackid: trackid,
        });
    }

    moveMap(lat, long) {
        if (lat != this.state.lastFocusLatitude || long != this.state.lastFocusLongitude) {
            this.setState({
                lastFocusLatitude: lat,
                lastFocusLongitude: long,
            });
            if (this.state.map != null && !(lat == 0 && long == 0)) {
                this.state.map.panTo({
                    lat: parseFloat(lat),
                    lng: parseFloat(long)
                });
            }
        }
    }

    setMap(map) {
        if (!this.state.map) {
            this.setState({
                map: map
            });

            this.getPOIdata();
            this.getMapdata();
        }
    }
    
    render() {
        if (this.state.redirectToAboutApp === true) {
            return <Redirect to='/aboutapp' />
        }

        if (this.state.hasInternet) {
            const POIMarkers = this.state.pois.map(poi => <POIMarker
                key = {"poi"+poi.id}
                trackid = {"poi"+poi.id}
                poi = {poi}
                lat={poi.latitude}
                lng={poi.longitude}
                Tooltip={"poi"+poi.id == this.state.trackid ? "true" : "false"}
                onClick={this.handleClick}
            />);

            const TruckMarkers = this.state.karren.map(kar => <TruckMarker
                key = {"kar"+kar.id}
                trackid = {"kar"+kar.id}
                kar = {kar}
                position={{lat: kar.latitude, lng: kar.longitude}}
                lat={kar.latitude}
                lng={kar.longitude}
                Tooltip={"kar"+kar.id == this.state.trackid ? "true" : "false"}
                onClick={this.handleClick}
            />);

            const UserMarkerIcon = (this.state.hasLocationPermission && this.state.userLocation != null ? <UserMarker
                lat={this.state.userLocation.lat}
                lng={this.state.userLocation.lng}
                Latitude={this.state.userLocation.lat}
                Longitude={this.state.userLocation.lng}
                trackid = {"user"}
                Tooltip={"user" == this.state.trackid ? "true" : "false"}
                onClick={this.handleClick}
            /> : null);

            const userCenterButton = (this.state.hasLocationPermission && this.state.userLocation != null ? <UserCenterButton
                Latitude={this.state.userLocation.lat}
                Longitude={this.state.userLocation.lng}
                trackid = {"user"}
                Tooltip={"user" == this.state.trackid ? "true" : "false"}
                onClick={this.handleClick}
            /> : null);

            const mapdivClass = (this.props.hideHeader ? "mapdivNoHeader" : "mapdiv");
            
            const contentClass = (this.props.hideHeader ? "content contentNoHeader" : "content");
            const button = (this.props.hideHeader ? <OpenAppButton handleMapClick={this.handleMapClick} /> : <CombinedButton handleMapClick={this.handleMapClick} />);
            const silentButton = (this.props.hideHeader ? null : <SilentCallButton handleMapClick={this.handleMapClick} />);

            const position = [51.505, -0.09];
            return (
                <main>
                    <div className={contentClass}>
                        {/* Important! Always set the container height explicitly */}
                        <div className={mapdivClass}>
                            <ContainerDimensions>
                                <MapContainer center={this.props.center} zoom={this.props.zoom} onClick={this.handleMapClick}>
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url={process.env.REACT_APP_API_HREF+"proxy.php?z={z}&x={x}&y={y}"}
                                        onLoad={(props) => apiIsLoaded(props.target._map, this.setMap)}
                                    />
                                    {UserMarkerIcon}
                                    {POIMarkers}
                                    {TruckMarkers}
                                </MapContainer>
                            </ContainerDimensions>
                            {button}
                            {userCenterButton}
                            {silentButton}
                        </div>
                    </div>
                </main>
            )
        } else {
            return (
                <main>
                    <div className="content">
                        <ErrorImage text="Je bent offline" subtext="Sommige onderdelen van de app werken mogelijk niet"/>
                    </div>
                </main>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        hideHeader: state.overlay.hideHeader || false,
    }
}

export default connect(mapStateToProps)(TrackerView);